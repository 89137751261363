import { useState, useRef, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Divider,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
  Stack,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import LenderFields from "./LenderFields";
import { deleteLenderSetting } from "../../../store/slices/userSlice";
import { REACT_APP_ALLOWED_LENDER_LIST } from "../../../constants";
import {
  lenderApi,
  useGetCommercialLendersQuery,
  useGetConsumerLendersQuery,
  useGetPersonalLendersQuery,
} from "../../../store/slices/apiSlice";
import PageLoader from "../../../components/Application/PageLoader";
import { reusableFetch } from "../../../utils/useAxios";

export default function SettingsSidebar() {
  const userData = useSelector(({ user }) => user);
  const { _id, lenderSettings } = userData;

  const { data: consumerData, isFetching: consumerIsFetching } = useGetConsumerLendersQuery();
  const { data: personalData, isFetching: personalIsFetching } = useGetPersonalLendersQuery();
  const { data: commercialData, isFetching: commercialIsFetching } = useGetCommercialLendersQuery();

  const lendersList = useMemo(() => {
    if (
      consumerData &&
      !consumerIsFetching &&
      personalData &&
      !personalIsFetching &&
      commercialData &&
      !commercialIsFetching
    ) {
      const lenderList = [
        ...consumerData?.data?.getAllLendersData?.docs,
        ...personalData?.data?.getAllLendersData?.docs,
        ...commercialData?.data?.getAllLendersData?.docs,
      ]
      const updatedLenderList = lenderList.filter((obj, index, self) =>
        index === self.findIndex((t) => t.lender === obj.lender)
      );

      return [
        ...updatedLenderList
      ];
    }
    return [];
  }, [consumerData, consumerIsFetching, personalData, personalIsFetching, commercialData, commercialIsFetching]);

  useEffect(() => {
    const request = window.indexedDB.open("financeableDB");
    let queries;
    request.onsuccess = async (event) => {
      const db = request.result;
      const objectStore = db.transaction(db.objectStoreNames[0], "readonly").objectStore("keyvaluepairs");
      const objectStoreData = objectStore.get("persist:root");

      objectStoreData.onsuccess = (e) => {
        if (objectStoreData.result) {
          const data = JSON.parse(objectStoreData.result ?? "");
          const lenderApiData = JSON.parse(data.lenderApi ?? "");
          queries = lenderApiData.queries;
          Object.keys(queries ?? {}).forEach(async (query) => {
            const queryTimestamp = Math.floor(queries[query].fulfilledTimeStamp / 1000) * 1000;
            if (["pending", "rejected"].includes(queries[query].status) || (await logTimestamp()) > queryTimestamp) {
              dispatch(lenderApi.endpoints.getConsumerLenders.initiate({}, { subscribe: true, forceRefetch: true }));

              dispatch(lenderApi.endpoints.getPersonalLenders.initiate({}, { subscribe: true, forceRefetch: true }));

              dispatch(lenderApi.endpoints.getCommercialLenders.initiate({}, { subscribe: true, forceRefetch: true }));
            }
          });
        }
      };
    };

    request.onerror = (e) => console.log("Error connecting to IndexDB");

    const logTimestamp = async () => {
      const { data } = await reusableFetch(`lender/log`, "GET", null);
      const lenderLog = data.data.lenderLog;
      const logTimestamp = Math.floor((new Date(lenderLog?.createdAt) || 0) / 1000) * 1000;
      return logTimestamp;
    };
  }, []);

  const allowedLenderList = lendersList
    .flat()
    .filter((product) => REACT_APP_ALLOWED_LENDER_LIST.includes(product.lender));

  const filteredLenderList = allowedLenderList.filter((obj, index, self) =>
    index === self.findIndex((item) => item.lender === obj.lender)
  );

  const dispatch = useDispatch();
  const lenderFieldRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedLender, setSearchedLender] = useState(null);
  const [expand, setExpand] = useState({});
  const [filteredLenderOption, setFilteredLenderOption] = useState([]);
  const [open, setOpen] = useState(false);
  const [isAddLenderDisable, setIsAddLenderDisable] = useState(true);

  const handleClickOpen = () => {
    handle.createLenderListFilter();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchedLender(null);
  };

  const addLenderDisable = (val) => {
    setIsAddLenderDisable(val);
  };

  const lenderSettingsNamesList = lenderSettings?.map((setting) => setting?.lender);

  const filterLenderDetail = () => {
    const newFilteredLenderList = filteredLenderList?.filter(
      (lender) => !lenderSettingsNamesList?.includes(lender.lender === "Dynamoney" ? "Grow" : lender.lender),
    );
    const lenderSettingOption = newFilteredLenderList.sort((a, b) => {
      if (a.lender.toLowerCase() === b.lender.toLowerCase()) {
        return a.lender.toLowerCase() > b.lender.toLowerCase() ? 1 : -1;
      }
      return a.lender.toLowerCase() > b.lender.toLowerCase() ? 1 : -1;
    });
    return lenderSettingOption;
  };

  const lenderSettingsWithLogos = lenderSettings?.map((setting) => {
    const logo = filteredLenderList?.find(
      (lender) => lender.lender === setting.lender || (lender.lender === "Dynamoney" && setting.lender === "Grow"),
    );
    return { logo: logo?.logoURL, ...setting, lenderCredentials: logo?.lenderCredentials };
  });

  const handle = {
    createLenderListFilter: () => {
      const filterData = filterLenderDetail();

      setFilteredLenderOption(filterData);
    },
    searchOnChange: (event, value, reason) => {
      if (reason === "clear") {
        setSearchTerm("");
        return;
      }
      setSearchTerm(value?.lender);
      setSearchedLender(value);
    },
    deleteLenderSetting: () => {
      const deleteLenderItemData = {
        user: _id || "",
        lenderSettings: lenderSettings[0]._id,
      };
      dispatch(deleteLenderSetting(deleteLenderItemData));
    },
  };

  <Grid
    container
    style={{
      marginTop: "64px",
      padding: "30px 0px 100px",
      height: "calc(100vh - 100px)",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <PageLoader text="Application loading..." />
  </Grid>;

  return consumerIsFetching || personalIsFetching || commercialIsFetching ? (
    <Grid
      container
      style={{
        marginTop: "64px",
        padding: "30px 0px 100px",
        height: "calc(100vh - 100px)",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <PageLoader text="Loading lender settings..." />
    </Grid>
  ) : (
    <>
      <Box width="100%">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack>
            <Typography variant="h4" style={{ marginBottom: "0px" }}>
              Lenders
            </Typography>
            <Typography style={{ marginBottom: "10px", fontSize: "13px" }}>
              Configure your settings for each lender such as their email address, broker credentials and more.
            </Typography>
          </Stack>
          <Button variant="contained" style={{}} onClick={handleClickOpen}>
            Add lender
          </Button>
        </Stack>
        <Divider sx={{ margin: "0 0 20px 0" }} />
        <Stack direction="column"></Stack>

        {lenderSettingsWithLogos
          // .filter((lender) => {
          // console.log(lender?.lender?.includes(searchTerm));
          //   if (
          //     searchTerm.length > 0 &&
          //     lender.lender
          //       .toLocaleLowerCase()
          //       .includes(searchTerm.toLocaleLowerCase())
          //   )
          //     return lender;

          //   if (searchTerm.length < 1) return lender;
          // })
          // .sort((a, b) => {
          //   const nameA = a.lender.toUpperCase(); // ignore upper and lowercase
          //   const nameB = b.lender.toUpperCase(); // ignore upper and lowercase

          //   if (nameA < nameB) {
          //     return -1;
          //   }
          //   if (nameA > nameB) {
          //     return 1;
          //   }

          //   // names must be equal
          //   return 0;
          // })
          ?.map((lenderSetting, index) => (
            <Card
              sx={{ minWidth: 275, margin: "0 0 10px 0" }}
              key={lenderSetting.lender}
              style={{
                transition: "all 0.3s ease-in-out",
                // height: 78,
                // height: expand[lender.lender] ? "300px" : "auto"
              }}
              elevation={1}
            >
              <CardContent
                // onClick={() => navigate(`${lender.lender}`)}

                sx={{ padding: "20px 20px !important" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack
                    direction="row"
                    // spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ width: "100%", margin: "0" }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      // style={{ margin: "0 0 10px 0" }}
                    >
                      <Box display="flex" alignItems="center">
                        {/* {console.log("lenderSetting",lenderSetting.logo)} */}
                        <img
                          height="30px"
                          component="img"
                          sx={{ height: 20 }}
                          src={lenderSetting.logo}
                          style={{ maxWidth: 75, height: "auto" }}
                          alt="green iguana"
                        />
                      </Box>
                      <Typography
                        style={{ marginLeft: 30 }}
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                          margin: 0,
                          padding: 0,
                          lineHeight: 1.75,
                        }}
                        color="text.secondary"
                      >
                        {lenderSetting.lender}
                      </Typography>
                    </Stack>
                    <Stack
                      gap={1}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          handle.deleteLenderSetting();
                        }}
                      >
                        Delete Configure
                      </Button> */}
                      <Button
                        variant="outlined"
                        size="small"
                        // onClick={() => setExpand({ [lender.lender]: false })}
                        onClick={() => {
                          // handle.createLenderSetting(lender.lender);
                          setExpand({
                            [lenderSetting.lender]: !expand[lenderSetting.lender],
                          });
                        }}
                      >
                        {expand[lenderSetting.lender] ? "Save" : "Edit"}
                      </Button>
                    </Stack>
                  </Stack>
                  {expand[lenderSetting.lender] && (
                    <Stack style={{ width: "100%" }} direction="column" alignItems="center" justifyContent="start">
                      <LenderFields
                        // {...{ lender }}
                        lenderSetting={lenderSetting}
                        // lenderCreatedData={lenderCreatedData}
                      />
                    </Stack>
                  )}
                </Box>
              </CardContent>
            </Card>
          ))}
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add lender</DialogTitle>
        <DialogContent sx={{ width: "600px" }}>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
          <Autocomplete
            fullWidth
            // open
            sx={{ margin: "0 0 20px 0" }}
            options={filteredLenderOption || []}
            value={searchTerm.lender}
            onChange={handle.searchOnChange}
            getOptionLabel={(lenderItem) => lenderItem?.lender || ""}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Search lender"
                variant="filled"
                // onChange={(event) => setSearchTerm(event.target.value)}
              />
            )}
          />
          <LenderFields
            ref={lenderFieldRef}
            lender={searchedLender}
            addLenderDisable={addLenderDisable}
            handleClose={handleClose}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button> */}
          <Button onClick={handleClose}>Close</Button>
          <Button
            disabled={isAddLenderDisable}
            onClick={() => {
              lenderFieldRef.current.handleCreateLenderSetting();
            }}
          >
            Add lender
          </Button>
        </DialogActions>
      </Dialog>

      {/* <CardActions>
                  <Button size="small">Learn More</Button>
              </CardActions> */}
    </>
  );
}
