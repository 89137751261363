import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import {
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { orange, red } from "@mui/material/colors";
import ArchiveTwoToneIcon from "@mui/icons-material/ArchiveTwoTone";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import UnarchiveTwoToneIcon from "@mui/icons-material/UnarchiveTwoTone";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import _, { debounce } from "lodash";
import moment from "moment";
import clsx from "clsx";
import { getApplicationTab, STATUS_CODE, ERRORS_MESSAGE, APPLICATION_TYPES } from "../../constants";
import { userSelector } from "../../store/slices/userSlice";
import {
  applicationsSelector,
  getAllApplications,
  saveValue,
  setApplicationFilterDate,
  updateApplicationStatus,
} from "../../store/slices/applicationsSlice";
import DataGridTable from "../dataGridTable/DataGridTable";
import ConfirmationDialog from "../customComponents/ConfirmationDialog";
import { getCloneApplication, userDetailsSelector } from "../../store/slices/applicationFormSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "61ch",
  },
}));

const dollarStringOptions = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
};

export default function ApplicationsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchTerm, setSearchTerm] = React.useState("");
  const user = useSelector(userSelector);
  const { gettingClonedApplication } = useSelector(userDetailsSelector);
  const { applications, page, isLoading, statusFilter, filterDate } = useSelector(applicationsSelector);
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [params, setParams] = useState({ offset: 1, limit: 10 });

  const filterOptions = ["All", "Active", "Archived", "Mine"];
  const localStatusFilterRef = React.useRef("Active");
  const pageSizeRef = React.useRef(10);

  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [unarchiveDialogOpen, setUnarchiveDialogOpen] = useState(false);
  const [dialogHandleFunction, setDialogHandleFunction] = useState(() => { });
  const [applicationHumanIdToUpdate, setApplicationHumanIdToUpdate] = useState("");

  const masterViewColumns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "humanId",
      headerName: "ID",
      type: "string",
      width: 90,
      editable: false,
      sortable: false,
    },
    {
      field: "startDate",
      headerName: "Date",
      type: "string",
      width: 80,
      editable: false,
      filterable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px" }}>{params.value.date}</Typography>
          <Typography style={{ fontSize: "11px", color: "#999" }}>{params.value.time}</Typography>
        </div>
      ),
    },
    // {
    //   field: "time",
    //   headerName: "Time",
    //   type: "string",
    //   width: 80,
    //   editable: false,
    // },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      width: 100,
      editable: false,
    },
    {
      field: "applicationType",
      headerName: "Application Type",
      type: "string",
      width: 110,
      editable: false,
    },
    {
      field: "broker",
      headerName: "Broker",
      type: "string",
      width: 120,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px" }}>
            {params.value.firstName} {params.value.lastName}
          </Typography>
          <Typography style={{ fontSize: "11px", color: "#999" }}>{params.value.org}</Typography>
        </div>
      ),
    },
    {
      field: "assetType",
      headerName: "Asset type",
      type: "string",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px", textWrap: "initial" }}>{params.value}</Typography>
        </div>
      ),
    },
    {
      field: "assetValue",
      headerName: "Asset value",
      type: "string",
      width: 110,
      editable: false,
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 220,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px", textWrap: "initial" }}>{params.value}</Typography>
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 220,
      editable: false,
      sortable: false, // Disable sorting
      filterable: false, // Disable filtering
      disableClickEventBubbling: gettingClonedApplication,
      renderCell: (params) => (
        <Stack direction="row" spacing={0.5}>
          <ButtonGroup
            variant="text"
            color="primary" // Use a theme color if needed
            sx={{
              "& .MuiButtonGroup-grouped:not(:last-of-type)": {
                borderColor: "rgba(0, 0, 0, 0.20)", // Divider color
              },
            }}
            aria-label="action button group"
          >
            <Tooltip title="Clone" arrow>
              <Button
                color="primary"
                // variant="outlined"
                size="large"
                disabled={params?.id && gettingClonedApplication}
                onClick={(e) => {
                  e.stopPropagation();
                  handle.cloneApplication(params?.id);
                }}
              >
                <ContentCopyTwoToneIcon />
              </Button>
            </Tooltip>
            {!/^archived$/i.test(params?.row?.status) && (
              <Tooltip title="Archive" arrow>
                <Button
                  disabled={/^archived$/i.test(params?.row?.status)}
                  // variant="outlined"
                  size="large"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDialogHandleFunction(() => () => handle.updateApplicationStatus(params?.id, "archived"));
                    setApplicationHumanIdToUpdate(params?.row?.humanId);
                    setArchiveDialogOpen(true);
                  }}
                >
                  <ArchiveTwoToneIcon color="warning" />
                </Button>
              </Tooltip>
            )}
            {/^archived$/i.test(params?.row?.status) && (
              <Tooltip title="Unarchive" arrow>
                <Button
                  disabled={!/^archived$/i.test(params?.row?.status)}
                  // variant="outlined"
                  size="large"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDialogHandleFunction(() => () => handle.updateApplicationStatus(params?.id, "workshop"));
                    setApplicationHumanIdToUpdate(params?.row?.humanId);
                    setUnarchiveDialogOpen(true);
                  }}
                >
                  <UnarchiveTwoToneIcon color="warning" />
                </Button>
              </Tooltip>
            )}

            <Tooltip title="Delete" arrow>
              <Button
                // variant="outlined"
                size="large"
                onClick={(e) => {
                  e.stopPropagation();
                  setDialogHandleFunction(() => () => handle.updateApplicationStatus(params?.id, "deleted"));
                  setApplicationHumanIdToUpdate(params?.row?.humanId);
                  setDeleteDialogOpen(true);
                }}
              >
                <DeleteTwoToneIcon color="error" />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Stack>
      ),
    },
    // {
    //   field: "entityName",
    //   headerName: "Entity",
    //   width: 250,
    //   // editable: true,
    // },
    // {
    //   field: "firstName",
    //   headerName: "First name",
    //   width: 150,
    //   // editable: true,
    // },
    // {
    //   field: "lastName",
    //   headerName: "Last name",
    //   width: 150,
    //   // editable: true,
    // },

    // {
    //   field: "term",
    //   headerName: "Term",
    //   type: "string",
    //   width: 110,
    //   editable: true,
    // },
    // {
    //   field: "serviceFees",
    //   headerName: "3rd Party fees",
    //   type: "string",
    //   width: 110,
    //   editable: true,
    // },
  ];

  const handle = {
    getAllApplicationsFn: ({ userId, cursor = 1, search, startDate, endDate, filter = "", isClearDateSelection = false }) => {

      const updatedStartDate = startDate ? startDate : moment(filterDate?.startDate).format("YYYY-MM-DD");
      const updatedEndDate = endDate ? endDate : moment(filterDate?.endDate).format("YYYY-MM-DD");

      dispatch(
        getAllApplications({
          userId,
          search,
          cursor,
          limit: pageSizeRef?.current || 10, // params?.limit
          requestedList: getApplicationTab.application,
          filter,
          columnFilter: params?.columnFilter,
          sort: params?.sort,
          startDate: (isClearDateSelection || !updatedStartDate || updatedStartDate === "Invalid date") ? undefined : updatedStartDate,
          endDate: (isClearDateSelection || !updatedEndDate || updatedEndDate === "Invalid date") ? undefined : updatedEndDate,
        }),
      );
    },
    handleApplicationSearch: (searchTerm) => {
      (searchTerm.length >= 2 || searchTerm.length <= 0) &&
        handle.getAllApplicationsFn({
          userId: user,
          cursor: params?.offset,
          search: searchTerm,
          filter: localStatusFilterRef.current,
        });
    },
    cloneApplication: (applicationId) => {
      dispatch(getCloneApplication(applicationId)).then(({ payload }) => {
        payload?.status_code === STATUS_CODE.success && navigate(`/application/${payload?.data?._id}`);
      });
    },
    updateApplicationStatus: (applicationId, status) => {
      dispatch(updateApplicationStatus({ applicationId, status })).then((res) => {
        if (res?.payload?.status === 200) {
          enqueueSnackbar(`Application ${status.toLowerCase()} successfully`, {
            variant: "success",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      });
      setDialogHandleFunction(() => { });

      handle.getAllApplicationsFn({
        userId: user,
        cursor: params?.offset,
        search: searchTerm,
        filter: localStatusFilterRef.current,
      });
    },
    handleFilterChange: async (option) => {
      handle.getAllApplicationsFn({
        userId: user,
        cursor: 1,
        // cursor: params?.offset,
        search: searchTerm,
        filter: option,
      });
      dispatch(saveValue({ statusFilter: option }));
      localStatusFilterRef.current = option;
    },
    onChangeDatePicker: (dates) => {
      const startDate = moment(dates?.[0]).format("YYYY-MM-DD");
      const endDate = moment(dates?.[1]).format("YYYY-MM-DD");

      dispatch(setApplicationFilterDate({ startDate: dates?.[0], endDate: dates?.[1] }))

      if ((dates[0] && dates[1]) || (!dates[0] && !dates[1])) {
        handle.getAllApplicationsFn({
          userId: user,
          cursor: 1,
          search: searchTerm,
          filter: localStatusFilterRef.current,
          startDate: startDate === "Invalid date" ? undefined : startDate,
          endDate: endDate === "Invalid date" ? undefined : endDate,
          isClearDateSelection: (dates?.[0] === null && dates?.[1] === null) ? true : false
        });
      }
    },
  };

  useEffect(() => {
    if (params?.offset) {
      handle.getAllApplicationsFn({
        userId: user,
        cursor: params?.offset,
        search: searchTerm,
        filter: statusFilter
      });
      dispatch(saveValue({ page: params?.offset, sort: params?.sort, limit: params?.limit }));
    }
  }, [params?.offset, params?.sort, params?.limit, params?.columnFilter]);

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(applications?.totalDocs || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) => applications?.totalDocs ?? prevRowCountState);
  }, [applications?.totalDocs]);

  useEffect(() => {
    const transformApplication = (doc) => {
      // const assetValueCheck = doc?.asset?.assetValue
      //   ? _.get(doc, "asset.assetValue").toLocaleString("en-US", dollarStringOptions)
      //   : "No Value";
      const assetValueCheck = doc?.applicationType === APPLICATION_TYPES.PERSONAL
        ? doc?.loanDetails.loanAmount
        : doc?.asset?.assetValue

      const date = new Date(_.get(doc, "createdAt"));
      const entityName = _.get(doc, "entities[0].entityName");
      const firstName = _.get(doc, "customers[0].firstName", "No");
      const lastName = _.get(doc, "customers[0].lastName", "customer");
      const assetType = _.get(doc, "asset.assetType", "Type not entered");
      const assetValue = assetValueCheck
        ? assetValueCheck.toLocaleString("en-US", dollarStringOptions)
        : "No Value";

      const capitalizedStatus = _.get(doc, "status", "").charAt(0).toUpperCase() + _.get(doc, "status", "").slice(1);
      const capitalizedApplicationType =
        _.get(doc, "applicationType", "").charAt(0).toUpperCase() + _.get(doc, "applicationType", "").slice(1);

      return _.pickBy(
        {
          humanId: _.get(doc, "humanId"),
          customer: entityName ? entityName : `${firstName} ${lastName}`,
          applicationType: capitalizedApplicationType,
          assetType,
          assetValue,
          loanAmount: _.get(doc, "loanDetails.loanAmount"),
          term: _.get(doc, "loanDetails.term"),
          id: _.get(doc, "_id"),
          startDate: {
            date: moment(date).format("D-M-YY"),
            time: moment(date).format("h:mm a"),
          },
          broker: {
            firstName: _.get(doc, "user.firstName"),
            lastName: _.get(doc, "user.lastName"),
            org: _.get(doc, "user.organisation[0].name"),
          },
          status: capitalizedStatus,
        },
        _.identity,
      );
    };

    const transformedRows = _.map(_.get(applications, "docs"), transformApplication);
    setRows(transformedRows);
    // time: moment(date).format("h:mm a"),
  }, [applications]);

  const debounced = useCallback(debounce(handle.handleApplicationSearch, 500), []);

  return (
    <Grid container item direction="column" spacing={0} style={{ padding: "20px" }}>
      <Grid
        item
        style={{
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
          display: "flex",
          alignItems: "center",
          columnGap: "50px",
        }}
      >
        <Typography
          variant="h1"
          style={{
            fontSize: "24px",
            fontWeight: 800,
            letterSpacing: "-0.5px",
          }}
        >
          Applications
        </Typography>
        <div className={classes.root}>
          <FormControl className={clsx(classes.margin, classes.textField)}>
            <Input
              className="input-border-bottom"
              id="application-search"
              autoComplete={false}
              variant="filled"
              placeholder="Search…"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                debounced(e.target.value, 1000);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                searchTerm && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setSearchTerm("");
                        searchTerm !== "" && debounced("", 1000);
                      }}
                      color="secondary"
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </FormControl>
        </div>
        <ButtonGroup variant="outlined">
          {filterOptions.map((option) => (
            <Button
              key={option}
              size="small"
              onClick={() => {
                handle.handleFilterChange(option);
              }}
              variant={statusFilter === option ? "contained" : "outlined"}
            >
              {option}
            </Button>
          ))}
        </ButtonGroup>

        <Stack style={{ marginBottom: '7px' }}>
          <DatePicker
            selected={filterDate?.startDate}
            onChange={handle.onChangeDatePicker}
            startDate={filterDate?.startDate}
            endDate={filterDate?.endDate}
            selectsRange
            isClearable
            name="Select Date"
            monthsShown={2}
            maxDate={new Date()} // Disable future dates
            customInput={<TextField label="Select Date" size="small" />}
          />
        </Stack>
      </Grid>

      <Grid item>
        <DataGridTable
          data={rows || []}
          columns={masterViewColumns}
          onRowClick={(event) => {
            navigate(`/application/${event.id}`);
          }}
          rowsPerPageOptions={[10, 20, 50, 100]}
          pageSize={pageSizeRef?.current || 10} // params?.limit
          rowCount={rowCountState}
          params={params}
          setParams={setParams}
          page={page}
          isLoading={isLoading}
          pageSizeRef={pageSizeRef}
        />
      </Grid>
      <ConfirmationDialog
        isOpen={archiveDialogOpen}
        handle={dialogHandleFunction}
        setIsOpen={setArchiveDialogOpen}
        title={
          <span>
            Are you sure you want to <span style={{ color: orange[500] }}>ARCHIVE</span> application
            {applicationHumanIdToUpdate ? ` ${applicationHumanIdToUpdate}` : ""}?
          </span>
        }
        proceedBtnColor={"warning"}
        proceedBtnText={"Archive"}
      />
      <ConfirmationDialog
        isOpen={deleteDialogOpen}
        handle={dialogHandleFunction}
        setIsOpen={setDeleteDialogOpen}
        title={
          <span>
            Are you sure you want to <span style={{ color: red[500] }}>DELETE</span> application
            {applicationHumanIdToUpdate ? ` ${applicationHumanIdToUpdate}` : ""}?
          </span>
        }
        proceedBtnColor={"error"}
        proceedBtnText={"Delete"}
      />
      <ConfirmationDialog
        isOpen={unarchiveDialogOpen}
        handle={dialogHandleFunction}
        setIsOpen={setUnarchiveDialogOpen}
        title={
          <span>
            Are you sure you want to <span style={{ color: orange[500] }}>UNARCHIVE</span> application
            {applicationHumanIdToUpdate ? ` ${applicationHumanIdToUpdate}` : ""}?
          </span>
        }
        proceedBtnColor={"warning"}
        proceedBtnText={"Unarchive"}
      />
    </Grid>
  );
}
